import Vue from "vue";

const state = {
	token: localStorage.getItem("token") || null
};

const getters = {
	isAuthenticated: (state) => !!state.token,
	getToken: (state) => state.token,
};

const actions = {
	login: ({ commit }, data) => {
		return new Promise((resolve, reject) => {
			Vue.axios
				.post("/auth/local", { identifier: data.identifier, password: data.password })
				.then(async (resp) => {
					//Vue.axios.defaults.headers.common["Authorization"] = resp.data.jwt;
					commit("token", resp.data.jwt);
					commit("profile/me", resp.data.user, { root: true });
					commit("site/setConfig", resp.data.user.site_configuration, { root: true });
					resolve(resp);
				})
				.catch((err) => {
					commit("logout");
					reject(err);
				});
		});
	},
	forgotPassword: ( _ , data) => {
		return new Promise((resolve, reject) => {
			Vue.axios
				.post("/auth/forgot-password", { email: data.email })
				.then(async (resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	logout: ({ commit }) => {
		return new Promise((resolve) => {
			commit("logout");
			resolve();
		});
	},
};

const mutations = {
	token: (state, token) => {
		localStorage.setItem("token", token);
		state.token = token;
	},
	logout(state) {
		localStorage.removeItem("token");
		state.token = null;

		localStorage.removeItem("me");
		state.me = {};
	},
};
export default {
	state,
	getters,
	actions,
	mutations,
	namespaced: true,
};
