import Vue from "vue";
import Vuex from "vuex";

import auth from "@/store/modules/auth.js";
import profile from "@/store/modules/profile.js";
import page from "@/store/modules/page.js";
import fonts from "@/store/modules/fonts.js";
import file from "@/store/modules/file.js";
import site from "@/store/modules/site.js";
import gallery from "@/store/modules/gallery.js";
import contentType from "@/store/modules/contentType.js";
import message from "@/store/modules/message.js";
import ui from "@/store/modules/ui.js";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		auth,
		profile,
		page,
		fonts,
		file,
		site,
		gallery,
		contentType,
		message,
		ui
	},
});
