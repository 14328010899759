<template>
    <v-app>
        <link href="https://fonts.googleapis.com/css?family=Material+Icons" rel="stylesheet" />
        <v-main v-if="ready">
            <!-- key trick to refresh on url param change -->
            <router-view  :key="$route.fullPath"/>

            <snackbar/>
        </v-main>
    </v-app>
</template>

<script>
import { mapState } from 'vuex'
import Snackbar from '@/components/ui/Snackbar'

export default {

    components: {
        Snackbar
    },

    data() {
        return {
            ready: false
        };
    },

    computed: {
        ...mapState({
            me: state => state.profile.me
        }),
    },
    mounted: async function(){

        if( this.me ){
            // get siteConfig
            await this.$store.dispatch("site/get", { id: this.me.site_configuration.id } )
                .catch((err) => {
                    console.log(err);
                });

            // get pages
            const query = {
                'host': this.me.site_configuration.host,
                '_publicationState': 'preview'
            }
            await this.$store
                .dispatch("page/index", query )
                .catch((err) => {
                    console.log(err);
                });
        }
        

        this.ready = true
    }
};
</script>

<style lang="scss">
    @import "~@/assets/scss/commons.scss";
</style>
