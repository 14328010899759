import Vue from "vue";

const state = {
    currentPage: null,
    pages: [],
    mainPages: [],
    footerPages: []
};

const getters = {
    componentDetails: (state) => (component) => {
        return state.pages
            .find( page => page.id === state.currentPage.id).Body
            .find( block => block.id === component.id && block.__component === component.__component)
    }
};

function normalize(page){
    if(page && page.Body){
        page.Body = page.Body.map(component => {
            if(component.isNew)
                delete component.id;

            return component
        });
    }
    return page;
}
const actions = {
    index: async ({ commit }, query ) => {
        return new Promise((resolve, reject) => {
            Vue.axios
                .get("/pages", { params: query } )
                .then((resp) => {
                    commit('setPages', resp.data);
                    resolve(resp);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    fetch: async ({ commit , rootState } ) => {

        const query = {
            'host': rootState.profile.me.site_configuration.host,
            '_publicationState': 'preview'
        }

        if(rootState.page.pages.length)
            return rootState.pages;
        else
            return new Promise((resolve, reject) => {
                Vue.axios
                    .get("/pages", { params: query } )
                    .then((resp) => {
                        commit('setPages', resp.data);
                        resolve(resp);
                    })
                    .catch(() => {
                        reject();
                    });
        });
    },
    create: ( _ , queries) => {
        return new Promise((resolve, reject) => {
            Vue.axios
                .post("/pages", queries.data)
                .then(async (resp) => {
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    update: ( _ , queries) => {
        return new Promise((resolve, reject) => {
            Vue.axios
                .put("/pages/" + queries.id , normalize(queries.data))
                .then(async (resp) => {
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    delete: ( _ , queries) => {
        return new Promise((resolve, reject) => {
            Vue.axios
                .delete("/pages/" + queries.id )
                .then(async (resp) => {
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    updateOrder: ( _ , items) => {
        let promises = []
        items.forEach( (item, index) => {
            const promise = new Promise( (resolve) => {
                const data = {
                    MenuOrder: index
                }
                Vue.axios.put("/pages/" + item.id , data).then( ( res ) => resolve(res) )
            });
            promises.push(promise)
        } )
        return Promise.all(promises)
    },
};

const mutations = {
    setCurrentPage(state, page) {
        state.currentPage = page;
    },
    setPages(state, pages) {
        state.pages = pages;

        state.mainPages = pages
            .filter( (page) => page.Menu == "Main" )
            .sort((a,b) =>  (a.MenuOrder ? a.MenuOrder : 0) - (b.MenuOrder ? b.MenuOrder : 0))

        state.footerPages = pages
            .filter( (page) => page.Menu == "Footer" )
            .sort((a,b) =>  (a.MenuOrder ? a.MenuOrder : 0) - (b.MenuOrder ? b.MenuOrder : 0))
    },
    setMainPages(state, mainPages) {
        state.mainPages = mainPages
    },
    setFooterPages(state, footerPages) {
        state.footerPages = footerPages
    },
    updatePage(state, pageContent){
       state.pages = state.pages.map( page => {
           return page.id === pageContent.id   ? pageContent : page;
       })
    },
    updateContent(state, content){
        if(content){
            state.pages.find(page => page.id === state.currentPage.id).Body = state.currentPage.Body.map(component => {
                return component.id === content.id  && component.__component === content.__component  ? content : component;
            })
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
