import Vue from "vue";

const state = {
	me: localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")) || null
};

const getters = {
	//
};

const actions = {
	/*me: ({commit}) => {
        return new Promise((resolve, reject) => {
            Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.token
            Vue.axios.get('/users/me')
                .then((resp) => {
                    commit()
                    resolve(resp)
                })
                .catch(() => {
                    reject()
                })
        })
    },*/
    update: async ({commit}, queries) => {
        return new Promise((resolve, reject) => {
            Vue.axios.put('/users/' + queries.id , queries.datas)
                .then((resp) => {
                    commit('me', resp.data)
                    resolve(resp)
                })
                .catch(() => {
                    reject()
                })
        })
    },
};

const mutations = {
    me: (state, me) => {
        localStorage.setItem("me", JSON.stringify(me));
        state.me = me;
    },
};
export default {
    namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
