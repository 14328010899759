import Vue from "vue";

const state = {
    config: null,
    defaultColors: ['#000000', '#7E7E7E', '#AEAEAE', '#DCDCDC', '#FFFFFF'],
};

const getters = {};

function normalize(site){

    let payload = {};
    for(const prop in site){

        if(site[prop] && typeof site[prop] === 'object'  )
        {
            if( Object.keys(site[prop]).length === 0){
                payload[prop] = null;
            }
            else{
                if( Object.prototype.hasOwnProperty.call(site[prop], 'url') && site[prop].url === null)
                    payload[prop] =  null
                else
                    payload[prop] = site[prop];

            }
        }
        else
            payload[prop] = site[prop];

    }
    console.log(payload.FooterLogo);
    return payload;
}
const actions = {
    get: ( { commit } , queries) => {
        return new Promise((resolve, reject) => {
            Vue.axios
                .get("/site-configurations/" + queries.id )
                .then(async (resp) => {
                    commit('setConfig', resp.data)
                    commit("gallery/setGallery", resp.data.defaultGallery, { root: true });
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    update: ( { commit } , queries) => {
        return new Promise((resolve, reject) => {
            Vue.axios
                .put("/site-configurations/" + queries.id , normalize(queries.data))
                .then(async (resp) => {
                    commit('setConfig', resp.data)
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};

const mutations = {
    setConfig(state, config){
        //logo
        if( config.logo && config.logo.url ){
            config.logo.url = process.env.VUE_APP_API_URL + config.logo.url
        }else{
            config.logo = {}
        }
        if( config.FooterLogo && config.FooterLogo.url ){
            config.FooterLogo.url = process.env.VUE_APP_API_URL + config.FooterLogo.url
        }else{
            config.FooterLogo = {}
        }
        // set empty values if any
        // legacy ?? move to component ?
        config.H1Color ??= '#000000'
        config.H1FontSize ??= '32'
        config.H1LineHeight ??= '48'
        config.H2Color ??= '#000000'
        config.H2FontSize ??= '24'
        config.H2LineHeight ??= '36'
        config.H3Color ??= '#000000'
        config.H3FontSize ??= '20'
        config.H3LineHeight ??= '30'
        config.TextColor ??= '#000000'
        config.TextFontSizeDesktop ??= '16'
        config.TextLineHeight ??= '24'
        config.ButtonOutlined ??= false
        config.ButtonColor ??= config.primaryColor

        state.config = config
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
