
const state = {
	editedContent: null
};

const getters = {
	//
};

const actions = {
	//
};

const mutations = {
    setEditedContent(state, content){
        state.editedContent = content
    }
};
export default {
    namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
