import Vue from "vue";

const state = {};

const getters = {};

const actions = {

    fetch:  async ({commit}, queries) => {
        return new Promise((resolve, reject) => {
            Vue.axios.get('/upload/files', {params: queries})
                .then((resp) => {
                    commit
                    resolve(resp.data)
                })
                .catch(() => {
                    reject()
                })
        })
    },
    count:  async ({commit}, queries) => {
        return new Promise((resolve, reject) => {
            Vue.axios.get('/upload/files/count', queries)
                .then((resp) => {
                    commit
                    resolve(resp.data)
                })
                .catch(() => {
                    reject()
                })
        })
    },
    upload: async ({commit}, queries) => {
        return new Promise((resolve, reject) => {
            Vue.axios.post('/upload', queries)
                .then((resp) => {
                    commit
                    resolve(resp)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    update: async ( _ , queries) => {
        return new Promise((resolve, reject) => {
            Vue.axios.post("/upload/?id=" + queries.id , queries.data)
                .then((resp) => {
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    delete: async ( _ , id) => {
        return new Promise((resolve, reject) => {
            Vue.axios.delete('/upload/files/' + id )
                .then((resp) => {
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};

const mutations = {};

export default {
    namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
