"use strict";

import Vue from "vue";
import axios from "axios";
import store from '../store'
import router from '../router'

const _axios = axios.create({
    baseURL: process.env.VUE_APP_API_URL || "",
});


_axios.interceptors.request.use(
    async function(config) {
        //if api ?
        const token = store.getters['auth/getToken']
        if( token ){
            config.headers.common['Authorization'] = "Bearer " + token
        }
        
        return config
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error)
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function(response) {
        // Do something with response data
        return response
    },
    function(error) {
        // Do something with response error

        // token is out of date
        if ( error.response.status == 401 && store.getters['auth/isAuthenticated'] ) {
            store.dispatch('auth/logout').then(()=> {
                router.push({name: 'Login'})
            })
        }
        return Promise.reject(error)
    }
);

Plugin.install = function (Vue) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            },
        },
        $axios: {
            get() {
                return _axios;
            },
        },
    });
};

Vue.use(Plugin);

export default Plugin;
