import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
	if ( !store.getters['auth/isAuthenticated'] ) {
		next();
		return;
	}
	next("/home");
};

const ifAuthenticated = (to, from, next) => {
	if ( store.getters['auth/isAuthenticated'] ) {
		next();
		return;
	}
	next("/login");
};

const router = new VueRouter({
	//mode: "history",
	routes: [
		{
			path: "/",
			redirect: "login",
		},
		{
			path: "/home",
			name: "Home",
			component: () => import("@/views/Home.vue"),
			beforeEnter: ifAuthenticated,
		},
		{
			path: "/login",
			name: "Login",
			component: () => import("@/views/Login.vue"),
			beforeEnter: ifNotAuthenticated,
		},
		{
			path: "/forgot-password",
			name: "ForgotPassword",
			component: () => import("@/views/ForgotPassword.vue"),
			beforeEnter: ifNotAuthenticated,
		},
		{
			path: "/my-account",
			name: "Profile",
			component: () => import("@/views/ProfileView.vue"),
			beforeEnter: ifAuthenticated,
		},
		{
			path: "/page/:id/gallery",
			name: "PageGallery",
			component: () => import("@/views/PageGallery.vue"),
			beforeEnter: ifAuthenticated,
			props: true
		},
		{
			path: "/gallery/",
			name: "Gallery",
			component: () => import("@/views/Gallery.vue"),
			beforeEnter: ifAuthenticated
		},
		{
			path: "/pages",
			name: "Pages",
			component: () => import("@/views/Pages.vue"),
			beforeEnter: ifAuthenticated,
		},
		{
			path: "/menu",
			name: "Menu",
			component: () => import("@/views/Menu.vue"),
			beforeEnter: ifAuthenticated,
		},
		{
			path: "/page/create/:location",
			name: "PageCreate",
			component: () => import("@/views/PageCreate.vue"),
			beforeEnter: ifAuthenticated
		},
		{
			path: "/page/:id/edit",
			name: "PageEdit",
			component: () => import("@/views/PageEdit.vue"),
			beforeEnter: ifAuthenticated,
			props: true
		},
		{
			path: "/settings",
			name: "Settings",
			component: () => import("@/views/Settings.vue"),
			beforeEnter: ifAuthenticated,
		},
		{
			path: "/page/:id/contents",
			name: "PageContents",
			component: () => import("@/views/PageContents.vue"),
			beforeEnter: ifAuthenticated,
			props: true
		},
	],
});

export default router;
