import Vue from "vue";

const state = {
    list: []
};

const getters = {
    siteConfiguration: (state)  => {
        return state.list.find(row => row.apiID === 'site-configuration')
    },
    siteConfigurationEnumField: (state, getters) => (fieldId) => {
        let enums = getters.siteConfiguration.schema.attributes[fieldId].enum
        //convert case (strapi enum regex workaround)
        enums = enums.map( field => field.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`) )
        return enums
    },

};

const actions = {
    index: async ({ commit }, query ) => {
        return new Promise((resolve, reject) => {
            Vue.axios
                .get("/content-type-builder/content-types", { params: query } )
                .then((resp) => {
                    commit('setList', resp.data.data);
                    resolve(resp);
                })
                .catch(() => {
                    reject();
                });
        });
    },
};

const mutations = {
    setList(state, list){
        state.list = list
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
