import Vue from "vue";

const state = {
    gallery: {
        images: [],
        Tempo: '3000',
        Transition: 'fade-transition',
        ButtonColor: "#ffffff",
        ButtonType: "filled",
        ButtonBackground: "#000000",
    },
};

const getters = {};

const actions = {
    get: ( { commit } , queries) => {
        return new Promise((resolve, reject) => {
            Vue.axios
                .get("/galleries/" + queries.id )
                .then(async (resp) => {
                    commit('setGallery', resp.data)
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    create: ( { commit } , queries) => {
        return new Promise((resolve, reject) => {
            Vue.axios
                .post("/galleries" , queries.data)
                .then(async (resp) => {
                    commit('setGallery', resp.data)
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    update: ( { commit } , queries) => {
        return new Promise((resolve, reject) => {
            Vue.axios
                .put("/galleries/" + queries.id , queries.data)
                .then(async (resp) => {
                    commit('setGallery', resp.data)
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};

const mutations = {
    setGallery(state, gallery){
        // set empty values if any
        // legacy ?? move to component ?
        gallery.Transition ??= 'v-window-x-transition'
        gallery.ButtonType ??= 'filled'
        gallery.ButtonBackground ??= '#ffffffff'
        gallery.ButtonColor ??= '#00000000'

        state.gallery = gallery
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
