import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@/plugins/axios";
import "@/plugins/vuebar";

Vue.config.productionTip = false;

import Icon from '@/components/ui/Icon'
Vue.component('icon', Icon)

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
