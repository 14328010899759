<template>
    <div class="snack-container">
        <v-snackbar 
            v-for="(message,i) in messages" 
            :key="i" 
            :value="true" 
            timeout="4000" 
            :color="message.color"
            right top
            elevation="0"
        >
            {{ message.text }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    icon depressed
                    v-bind="attrs"
                    @click="hide(i)"
                >
                    <icon class="icon icon-close" size="10"></icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {

    props: {
        //
    },

    data() {
        return {
            //
        };
    },

    computed: {
        ...mapState({
            messages: state => state.message.stack,
        }),
    },

    mounted: function(){
        //
    },

    methods: {
        hide(i){
            this.messages.splice(i,1)
        }
    }

}
</script>

<style scoped lang="scss">
.snack-container{
    position: absolute;
    top: 60px;
    right: 10px;

    .v-snack{
        position: relative;
        height: auto;
    }
}
</style>
