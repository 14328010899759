
const state = {
	stack: []
};

const getters = {
	//
};

const actions = {
	//
};

const mutations = {
    add(state, message){
        state.stack.push(message)
    }
};
export default {
    namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
