
const state = {
    list: [
        "AbrilFatface",
        "Akshar",
        "AlfaSlabOne",
        "Anton",
        "BarlowSemiCondensed",
        "BebasNeue",
        "ConcertOne",
        "DancingScritp",
        "Domine",
        "FredokaOne",
        "GrapeNuts",
        "GreatVibes",
        "Koulen",
        "Lobster",
        "Montserrat",
        "MontserratAlternates",
        "Oswald",
        "Pacifico",
        "PermanentMarker",
        "Poppins",
        "Prata",
        "Questrial",
        "Raleway",
        "Roboto",
        "RobotoSlab",
        "Sacramento",
        "SecularOne",
        "Smooch",
        "SpaceMono",
        "Staatliches",
        "Tajawal",
        "ZenLoop"
    ],
};

export default {
    namespaced: true,
    state
};
