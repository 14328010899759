import Vue from "vue";
import Vuetify from "vuetify/lib";
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: "#1E232F",
				secondary: "#009A75",
				success: "#009A75",
				accent: "#8c9eff",
				error: "#b71c1c",
			},
		},
	},
	icons: {
		iconfont: "mdiSvg", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
	},
});
