<template>
    <span v-bind="$attrs" v-on="$listeners" :style="style"></span>
</template>

<script>

export default {
    props: {
        size: {
            type: [Number, String],
            default: '20'
        },
        color: {
            type: String,
            default: 'inherit'
        }
    },

    data() {
        return {};
    },

    computed: {
        style: function(){
            return { 
                fontSize: this.size + 'px' ,
                color: this.color 
            }
        }
    },

    mounted() {
       //
    },

    methods: {
        //
    }
}
</script>

<style scoped>

</style>
